"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const material_1 = require("@mui/material");
const icons_material_1 = require("@mui/icons-material");
const auth0_react_1 = require("@auth0/auth0-react");
const constants_1 = require("@appConstants/constants");
const socket_1 = require("@services/socket");
const kioskSlice_1 = require("@redux/reducers/kioskSlice");
const currentUserSlice_1 = require("@redux/reducers/currentUserSlice");
require("./connector.scss");
const KioskPersistConnector = () => {
    let reconnectInterval;
    const parentclasses = ['parent'];
    const num = new Date().getHours();
    parentclasses.push(`skgrd${String(num).padStart(2, '0')}`);
    const dispatch = (0, react_redux_1.useDispatch)();
    const { getAccessTokenSilently } = (0, auth0_react_1.useAuth0)();
    const { tenantId, token } = (0, react_redux_1.useSelector)((state) => state.currentUserReducer);
    const { kioskNumber } = (0, react_redux_1.useSelector)((state) => state.kioskReducer);
    const [messageService, setMessageService] = (0, react_1.useState)(false);
    const [connectionStatus, setConnectionStatus] = (0, react_1.useState)();
    const generateToken = () => __awaiter(void 0, void 0, void 0, function* () {
        const newToken = yield getAccessTokenSilently({
            authorizationParams: {
                audience: constants_1.AUTH_AUDIENCE,
                scope: constants_1.AUTH_SCOPES,
            },
        });
        if (newToken) {
            dispatch((0, currentUserSlice_1.setToken)(newToken));
        }
    });
    (0, react_1.useEffect)(() => {
        if (!messageService) {
            reconnectInterval = setInterval(() => {
                initMessageService();
            }, constants_1.FIVE_SECOND);
        }
        return () => clearInterval(reconnectInterval);
    }, [connectionStatus, token, tenantId, messageService, kioskNumber]);
    const messageCallback = (message) => {
        switch (message.MessageType) {
            case 'closed':
                generateToken();
                setMessageService(false);
                setConnectionStatus(constants_1.PERSISTANT_CONNECTION_STATUS.CLOSED);
                break;
            case 'welcome':
                setConnectionStatus(constants_1.PERSISTANT_CONNECTION_STATUS.CONNECTED);
                break;
            case 'kiosk-number':
                if (message.data && message.data.kioskNumber) {
                    dispatch((0, kioskSlice_1.setKioskNumber)(message.data.kioskNumber));
                }
                break;
            case 'kioskNewForm':
                const parsedData = JSON.parse(message.data);
                if (parsedData && parsedData.formUrl) {
                    dispatch((0, kioskSlice_1.setFormsToFillOut)([parsedData.formUrl]));
                    (0, socket_1.sendMessage)('kioskNewFormResponse', {});
                }
                break;
            default:
                break;
        }
    };
    const initMessageService = () => __awaiter(void 0, void 0, void 0, function* () {
        if (tenantId && token) {
            (0, socket_1.init)(tenantId, token, messageCallback, true, kioskNumber.toString());
            setMessageService(true);
        }
    });
    let content = ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'connected-container', children: (0, jsx_runtime_1.jsx)(material_1.Box, { className: parentclasses.join(' '), children: (0, jsx_runtime_1.jsx)(icons_material_1.CloudQueue, { className: 'connecting-icon' }) }) }));
    if (connectionStatus === constants_1.PERSISTANT_CONNECTION_STATUS.CLOSED) {
        content = ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'lost-connected', children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { children: "Refresh to reconnect" }), (0, jsx_runtime_1.jsx)(icons_material_1.CloudOff, { className: 'lost-connected-icon' })] }));
    }
    else if (connectionStatus === constants_1.PERSISTANT_CONNECTION_STATUS.CONNECTED) {
        content = ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'connected-container', children: (0, jsx_runtime_1.jsx)(material_1.Box, { className: parentclasses.join(' '), children: (0, jsx_runtime_1.jsx)(icons_material_1.Cloud, { className: 'connected-icon' }) }) }));
    }
    return (0, jsx_runtime_1.jsx)(material_1.Box, { children: content });
};
exports.default = KioskPersistConnector;
