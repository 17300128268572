"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.makrConversationHandled = exports.sendMessageToPatient = exports.fetchMoreMessages = exports.fetchConversations = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const initialize_instance_1 = require("@apis/initialize.instance");
exports.fetchConversations = (0, toolkit_1.createAsyncThunk)('conversations/fetchConversations', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { currentUser, tenantId, token } = storeState.currentUserReducer;
        const { loaded } = storeState.conversationsReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        const response = yield initialize_instance_1.conversationInstance.get(`${tenantId}/${loaded}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (!response || !response.data) {
            return null;
        }
        const result = response.data;
        if (result && result.total > result.loaded) {
            setTimeout(() => {
                thunkApi.dispatch((0, exports.fetchConversations)());
            }, 200);
        }
        return result;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchMoreMessages = (0, toolkit_1.createAsyncThunk)('conversations/fetchMoreMessages', ({ sessionNumber, timestamp }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { currentUser, tenantId, token } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        if (!sessionNumber) {
            return Promise.reject('nosessionNumber');
        }
        if (!timestamp) {
            return Promise.reject('notimestamp');
        }
        const response = yield initialize_instance_1.conversationInstance.get(`messages/${tenantId}/${sessionNumber}/${timestamp}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (!response || !response.data) {
            return { totalMessages: 0, messages: [] };
        }
        const result = response.data;
        return result;
    }
    catch (err) {
        throw err;
    }
}));
exports.sendMessageToPatient = (0, toolkit_1.createAsyncThunk)('conversations/sendMessageToPatient', ({ number, message }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { currentUser, tenantId, token } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        if (!number) {
            return Promise.reject('noNumber');
        }
        if (!message) {
            return Promise.reject('noMessage');
        }
        const response = yield initialize_instance_1.conversationInstance.post(`send-message/${tenantId}`, { number, message }, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (!response || !response.data) {
            return null;
        }
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.makrConversationHandled = (0, toolkit_1.createAsyncThunk)('conversations/makrConversationHandled', ({ messageId, handled }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { currentUser, tenantId, token } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        if (!messageId) {
            return Promise.reject('nomessageId');
        }
        const response = yield initialize_instance_1.conversationInstance.post(`mark-message-handled/${messageId}`, { messageId, handled }, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (!response || !response.data) {
            return null;
        }
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
