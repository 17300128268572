"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetTreatmentPlans = exports.treatmentPlansSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const constants_1 = require("@appConstants/constants");
const treatmentPlan_1 = require("@services/treatmentPlan");
const initialState = {
    list: [],
    listStatus: constants_1.REQUEST_STATUS.IDLE,
    haveMore: true,
    totalCount: 0,
    totalAmount: 0,
};
exports.treatmentPlansSlice = (0, toolkit_1.createSlice)({
    name: 'treatmentPlan',
    initialState,
    reducers: {
        resetTreatmentPlans: (state) => {
            state.list = [];
            state.haveMore = true;
            state.listStatus = constants_1.REQUEST_STATUS.IDLE;
            state.totalCount = 0;
            state.totalAmount = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(treatmentPlan_1.requestTreatmentPlans.pending, (state) => {
            state.listStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(treatmentPlan_1.requestTreatmentPlans.fulfilled, (state, action) => {
            state.listStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            if (action.payload && Array.isArray(action.payload.list)) {
                const planList = [...state.list, ...action.payload.list];
                if (planList.length > 0) {
                    const key = 'Id';
                    const unique = [...new Map(planList.map((item) => [item[key], item])).values()];
                    state.list = unique;
                    state.totalCount = action.payload.count;
                    state.totalAmount = action.payload.totalAmount;
                    state.haveMore = state.list.length < state.totalCount;
                }
            }
            else {
                state.listStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error in getting treatment plans', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(treatmentPlan_1.requestTreatmentPlans.rejected, (state, action) => {
            state.listStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error in getting treatment plans', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
exports.resetTreatmentPlans = exports.treatmentPlansSlice.actions.resetTreatmentPlans;
exports.default = exports.treatmentPlansSlice.reducer;
