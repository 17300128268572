"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MERCHANT_CONFIG_SCHEMA = exports.STEP_THREE_MERCHANT_SCHEMA = exports.STEP_TWO_MERCHANT_SCHEMA = exports.STEP_ONE_MERCHANT_SCHEMA = void 0;
const Yup = __importStar(require("yup"));
exports.STEP_ONE_MERCHANT_SCHEMA = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.string().matches(/^\d+$/, 'Phone number must contain only digits').required('Phone number is required'),
    ownerTitle: Yup.string().required('Owner title is required'),
    countryCode: Yup.string().required('Country code code is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('Zip/Postal code is required'),
    address: Yup.string().required('Address code is required'),
});
exports.STEP_TWO_MERCHANT_SCHEMA = Yup.object({
    salesCode: Yup.string().required('Sales code is required'),
    dbaName: Yup.string().required('DB name is required'),
    legalBusinessName: Yup.string().required('Business name is required'),
    taxFilingName: Yup.string().required('Tax filing name is required'),
    taxFilingMethod: Yup.string().required('Tax filing method is required'),
    address1: Yup.string().required('Address is required'),
    city1: Yup.string().required('City is required'),
    stateCode1: Yup.string().required('State code code is required'),
    zipCode1: Yup.string().required('Zip/Postal code is required'),
    countryCode1: Yup.string().required('Country code code is required'),
    address2: Yup.string().required('Address is required'),
    city2: Yup.string().required('City is required'),
    stateCode2: Yup.string().required('State code code is required'),
    zipCode2: Yup.string().required('Zip/Postal code is required'),
    countryCode2: Yup.string().required('Country code code is required'),
});
exports.STEP_THREE_MERCHANT_SCHEMA = Yup.object({
    bankAcctNum1: Yup.string().required('Bank account Number is required'),
    bankRoutingNum1: Yup.string().required('Bank routing Number is required'),
    bankAcctTypeCd1: Yup.string().required('Bank account type card is required'),
    bankName1: Yup.string().required('Bank name is required'),
    bankAcctNum2: Yup.string().required('Bank account Number is required'),
    bankRoutingNum2: Yup.string().required('Bank routing Number is required'),
    bankAcctTypeCd2: Yup.string().required('Bank account type card is required'),
    bankName2: Yup.string().required('Bank name is required'),
});
exports.MERCHANT_CONFIG_SCHEMA = Yup.object().shape({
    userName: Yup.string().required('User Name is required').min(2, 'User Name must be at least 2 characters'),
    password: Yup.string().required('Password is required'),
    clientSecret: Yup.string().required('Client Secret is required'),
    salesCode: Yup.string().required('Sales Code is required'),
    templateId: Yup.string().required('Template ID is required'),
    clientId: Yup.string().required('Client ID is required'),
    merchantId: Yup.string().required('Merchant ID is required'),
    type: Yup.string().required('Type is required'),
});
